import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "about" */ '../views/users/IndexView.vue')
      },
      {
        path: '/events',
        name: 'events',
        component: () => import(/* webpackChunkName: "about" */ '../views/events/IndexView.vue'),
        children: [
          {
            path: "/evento",
            component: () => import("../views/events/EventosView"),
          },
          {
            path: "/zona",
            component: () => import("../views/events/ZonasView"),
          },
          {
            path: "/programacion",
            component: () => import("../views/events/ProgramacionView"),
          },        

        ]
      },
      {
        path: '/sales',
        name: 'sales',
        component: () => import(/* webpackChunkName: "about" */ '../views/sales/IndexView.vue'),
        children: [
          {
            path: "/ventas",
            name: 'ventas',
            component: () => import("../views/sales/SalesView"),
          }
        ]

      },
      {
        path: "/individual",
        name: 'individual',
        component: () => import("../views/sales/IndividualView"),
      }
      ,
      {
        path: "/masiva",
        name: 'masiva',
        component: () => import("../views/sales/MasivaView"),
      },
      {
        path: '/procesarVenta',
        name: 'procesarVenta',
        component: () => import('../views/sales/ProcesarView.vue')
      },
      {
        path: '/ProcesarMasivaView',
        name: 'ProcesarMasivaView',
        component: () => import('../views/sales/ProcesarMasivaView.vue')
      },
      {
        path: '/config',
        name: 'config',
        component: () => import('../views/config/IndexView.vue')
      },
      {
        path: "/voucher",
        name: 'voucher',
        component: () => import("../views/sales/voucher/IndexView"),
        children: [
          {
            path: '/listaVouchers',
            name: 'listaVouchers',
            component: () => import(/* webpackChunkName: "about" */ '../views/sales/voucher/VoucherView.vue')
          }
        ]
      },
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Guard de navegación global
router.beforeEach((to, from, next) => {
  // Verifica si el localStorage tiene un valor que indica autenticación
  const isAuthenticated = localStorage.getItem('usuario');
  const token = localStorage.getItem('token');

  // Si el usuario no está autenticado y no está tratando de acceder a la ruta de login, redirige a login
  if ((!isAuthenticated || !token) && to.name !== 'login') {
    next({ name: 'login' });
  } else {
    next(); // Deja que la navegación continúe
  }
});

export default router;