<template>
  <div><router-view /></div>

</template>

<script>
import Auth from "./services/Auth";
export default {
  components:{

  },setup(){
    

  },data(){

    return{
      usuario: window.localStorage.getItem("usuario"),
      nro_telefono: window.localStorage.getItem("nro_telefono"),      
    }

  },mounted(){
    let loader = this.$loading.show();

    this.$nextTick(() => {

      const data = {
        usuario: this.usuario,
        nro_telefono: this.nro_telefono
        };
        
        /*
        FALTA VALIDACION DE USUARIOy
        */

      Auth.checkAuth(data)
            .then(response => {
              console.log("Respuesta del servidor:", response);
              // Maneja la respuesta aquí
            })
            .catch(error => {
              localStorage.clear();
              this.$router.push('/login');
              console.error("Error:", error);
            });

    })

    loader.hide();

  }

}

</script>
<style>

@import "./assets/css/style.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

