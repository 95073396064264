import http from "../config/axios";

class Auth {
  getApi() {
    return http.post(`/security/auth/getApi`);
  }
  login(data) {
    console.log(data);
    return http.post(`/security/auth/login`, data);
  }
  checkAuth(data) {
    console.log(data);
    return http.post(`/security/auth/checkAuth`, data);
  }
  registroUsuario(data) {
    return http.post(`/security/auth/register`, data);
  }
  listaUsuarios() {
    return http.post(`/security/auth/userList`,);
  }

  listaRegiones() {
    return http.post(`/security/auth/listaRegiones`,);
  }

}


export default new Auth();